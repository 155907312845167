/* Reset CSS */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  height: 100vh;
}
ol,
ul {
  white-space: normal;
  list-style-position: inside;
}
blockquote,
q {
  white-space: normal;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  color: #4652fd !important;
  border: 0 !important;
  height: auto;
  white-space: normal;
  word-wrap: break-word;
  padding: 0 !important;
  font-size: 14px !important;
  line-height: 1.2;
  cursor: pointer;
  -moz-transition: 0.1s ease;
  -o-transition: 0.1s ease;
  -webkit-transition: 1s ease;
  transition: 0.1s ease;
}

/* Dummy Font Replace later*/
@font-face {
  font-family: 'Roboto-Bold';
  src: url('./assets/fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./assets/fonts/Roboto-Regular.ttf');
}
